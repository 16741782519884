/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 19:09:10
 * @Module: 充值记录
 */
 <template>
  <div style="background:#fff;overflow:hidden">
    <div style="padding:0 20px 20px;">
      <hdqForm :showLabel="['date','coinType']" @search="search" ref="hdqForm" />
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="充值时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createdAt|timeFormat('yyyy-mm-dd hh:MM:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="coinType" label="充值类型">
          <template slot-scope="scope">
            <div v-if="scope.row.coinType=='COIN'" class="flex">
              <div>金币</div>
              <img src="@/static/image/icon_jinbi.png" style="margin-left:4px;width:16px;height:16px;" alt="金币">
            </div>
            <div v-else class="flex">
              <div>卡券</div>
              <img src="@/static/image/icon_dianquan.png" style="margin-left:4px;width:16px;height:16px;" alt="卡券">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="coins" label="充值数量">
          <template slot-scope="scope">
            <span v-if="scope.row.coinType=='COIN'">{{ scope.row.coins }}金币</span>
            <span v-else>{{ scope.row.coins }}卡券</span>
          </template>
        </el-table-column>

        <el-table-column prop="price" label="花费金额（元）">
          <template slot-scope="scope">
            <span style="color:#0062FF">{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="账户余额">
          <template slot-scope="scope">
            <span>{{ scope.row.balance }}{{scope.row.coinType=='COIN'?'金币':'卡券'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付方式">
          <template slot-scope="scope">
            <div v-if="scope.row.payment=='ALIPAY'">
              <i class="el-icon-ali-zhifubao" style="color:#0062FF"></i>
              <span style="margin-left:6px;">支付宝</span>
            </div>
            <div v-else-if="scope.row.payment=='GIVEAWAY'">
              <i class="el-icon-present" style="color:#FF8412"></i>
              <span style="margin-left:6px;">系统赠送</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </div>
  </div>
</template>
 <script>
import hdqForm from "@/components/hdqForm.vue";
import hdqPagination from "@/components/hdqPagination.vue"
export default {
  components: {
    hdqForm,
    hdqPagination
  },
  data () {
    return {
      tableData: []
    }
  },
  mounted () {
    this.getRechargeList()
  },
  filters: {
    payment (res) {
      if (res == 'ALIPAY') {
        return '支付宝'
      } else if (res == 'GIVEAWAY') {
        return '系统赠送'
      } else {
        return res
      }
    }
  },
  methods: {
    search () {
      this.$refs.pagination.createPagination({ current: 1 })
      this.getRechargeList()
    },
    paginationChange () {
      this.getRechargeList()
    },
    async getRechargeList () {
      const { current, size } = this.$refs.pagination
      const formData = this.$refs.hdqForm.getForm();
      const { data, status } = await this.$api.getRechargeList({ current, size, ...formData })
      if (status == 200) {
        this.tableData = data.records
        this.$refs.pagination.createPagination(data)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>